// i18next-extract-mark-ns-start visa-mastercard-for-shopify
//Payment methods
import {Background} from 'components/Background';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {List} from 'components/List';
import {Content} from 'components/Content';
import {Partners} from 'components/Partners';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';

//Partners
import index2 from 'images/best_payment_gateway_rates.svg';

//Images
import shopify_visamastercard from 'images/shopify_visa-mastercard.svg';

// Clients
import index1 from 'images/payment_gateway.svg';
import index3 from 'images/shopify_payment_gateway.svg';

import React from 'react';
import styled from 'styled-components';
import {AnchorLink} from 'components/AnchorLink';
import {IndexImage} from 'components/landings/IndexImage';
import {IndexBackground} from 'components/landings/IndexBackground';
import {BlogLink} from 'components/links/Blog';
import {InternalPageLink} from 'components/links/Pages';

const IndexContent = styled.div`
  padding-top: 90px;
  max-width: 500px;
  @media (max-width: 768px) {
    padding-top: 10px;
  }
`;

const LargeSectionHeader = styled(SectionHeader)`
  h1 {
    font-size: 2.625rem;
    line-height: 3.25rem;

    @media (max-width: 768px) {
      font-size: 2rem;
      line-height: 2.75rem;
    }
  }
`;

const SellingPage: React.FC = () => {
  const {t} = useI18next();
  return (
    <>
      <SEO
        path="visa-mastercard-for-shopify"
        title={t('Add Credit Card Payments to Shopify')}
        description={t(
          'Add credit card payments to your Shopify store with MONEI’s e-commerce payment gateway. Including Visa, Mastercard, JCB, Diners, and UnionPay. Start here ››'
        )}
      />
      <IndexBackground>
        <Content>
          <Section reverseOnMobile>
            <IndexContent>
              <LargeSectionHeader underline tagName="h1">
                <Trans>Quickly and easily add credit card payments to your Shopify store</Trans>
              </LargeSectionHeader>
              <Trans parent="p">
                You’ve picked your Shopify theme and uploaded your products to sell. Now it’s time
                to add credit card payments to your Shopify store. But to do this, you’ll need an
                e-commerce payment gateway that supports credit card payments and integrates with
                Shopify.
              </Trans>
              <Trans parent="p">
                Use our advanced e-commerce payment gateway to accept and manage all payment methods
                in a single platform. Create an account to start accepting credit card payments in
                your Shopify store today. This includes fast and secure payments from major networks
                like Visa, Mastercard, and more.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </IndexContent>
            <IndexImage
              src={shopify_visamastercard}
              alt="MONEI visa mastercard"
              title="MONEI visa mastercard"
            />
          </Section>
        </Content>
      </IndexBackground>
      <Background>
        <Content>
          <Section>
            <SectionImage
              src={index2}
              alt="MONEI Payment Gateway"
              title="MONEI Payment Gateway"
              width={600}
              height={350}
              mobileWidth={280}
            />
            <div>
              <SectionHeader>
                <Trans>
                  Accept credit card payments and alternative payment methods in your Shopify store
                </Trans>
              </SectionHeader>
              <Trans parent="p">
                <InternalPageLink slug="payment-methods/credit-cards">
                  Credit card payments
                </InternalPageLink>{' '}
                are popular, but they shouldn’t be the only{' '}
                <InternalPageLink slug="payment-methods">online payment method</InternalPageLink>{' '}
                you accept in your Shopify store. Consumers today are looking for more ways to pay
                online including{' '}
                <BlogLink slug="digital-wallet-ecommerce">digital wallets</BlogLink> like{' '}
                <InternalPageLink slug="payment-methods/paypal">PayPal</InternalPageLink>,{' '}
                <BlogLink slug="apple-pay-ecommerce-integration">Apple Pay</BlogLink>,{' '}
                <InternalPageLink slug="payment-methods/google-pay">Google Pay</InternalPageLink>,
                and{' '}
                <InternalPageLink slug="payment-methods/click-to-pay">
                  Click to Pay
                </InternalPageLink>
                . As well as <BlogLink slug="local-payment-methods">local payment methods</BlogLink>{' '}
                such as <InternalPageLink slug="payment-methods/bizum">Bizum</InternalPageLink> in
                Spain.
              </Trans>
              <Trans parent="p">
                We want to help you accept more payment methods so you can reach more people,
                increase customer satisfaction, and sell more. That’s why we aggregate all major and{' '}
                <BlogLink slug="alternative-payment-methods">alternative payment</BlogLink> methods
                into a single platform.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Discover MONEI</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
          <Section reverseOnMobile>
            <div>
              <SectionHeader>
                <Trans>Approve more online payments and increase conversions</Trans>
              </SectionHeader>
              <Trans parent="p">
                Approve more payments, improve the checkout experience, and increase your conversion
                rate with our{' '}
                <InternalPageLink slug="features/payments-orchestration">
                  payments orchestration
                </InternalPageLink>{' '}
                feature. Rather than depending on{' '}
                <InternalPageLink slug="redsys-alternative">Redsys</InternalPageLink> alone, you’ll
                be able to create payment processing rules. Choose your default processor and if
                it’s experiencing downtime, we’ll route payments to another one. This will reduce
                online payment{' '}
                <BlogLink slug="online-payment-failure-message">failure messages</BlogLink> and lost
                sales, and your customers will enjoy a{' '}
                <BlogLink slug="online-payment-failure-message">frictionless payment</BlogLink>{' '}
                experience.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Get MONEI Now</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
            <SectionImage
              src={index1}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={500}
              height={500}
              mobileWidth={280}
            />
          </Section>
          <Section>
            <SectionImage
              src={index3}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={400}
              height={428}
              mobileWidth={180}
            />
            <div>
              <SectionHeader>
                <Trans>Enjoy the lowest Shopify payment gateway transaction fees</Trans>
              </SectionHeader>
              <Trans parent="p">
                Your success is our success. That’s why we’re the only payment gateway to offer
                dynamic pricing — as you sell more, you’ll pay less on transaction fees. You’ll make
                more money per order that you can reinvest to grow your store.
              </Trans>
              <Trans parent="p">
                Choose a flexible{' '}
                <InternalPageLink slug="shopify-payment-gateway">
                  Shopify payment gateway
                </InternalPageLink>{' '}
                that scales with your business —{' '}
                <InternalPageLink slug="pricing">view pricing here</InternalPageLink>.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
        </Content>
      </Background>
      <Content fullWidth>
        <Section style={{maxWidth: 900, margin: 'auto'}}>
          <div>
            <SectionHeader underline tagName="h3">
              <Trans>How to configure Shopify credit card payments</Trans>
            </SectionHeader>
            <Trans parent="p">
              Follow these steps to quickly and easily add credit card payments to your Shopify
              store:
            </Trans>
            <List>
              <Trans parent="li">
                <AnchorLink href="https://dashboard.monei.com/?action=signUp">
                  Sign up for MONEI here
                </AnchorLink>
              </Trans>
              <Trans parent="li">
                <AnchorLink href="https://support.monei.com/hc/es-es/articles/360018045518-How-do-I-activate-the-credit-card-payment-method-">
                  Configure credit card payments
                </AnchorLink>{' '}
                in your MONEI dashboard
              </Trans>
              <Trans parent="li">
                <AnchorLink href="https://docs.monei.com/docs/e-commerce/shopify/">
                  Connect MONEI
                </AnchorLink>{' '}
                to your Shopify store
              </Trans>
            </List>
            <Partners />
          </div>
        </Section>
      </Content>
    </>
  );
};

export default SellingPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "visa-mastercard-for-shopify"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
